import React from "react"
import { Link } from "gatsby"
import { ThemeProvider } from "gatsby-interface"

import Container from "../components/container"
import Title from "../components/hero/title"
import Subtitle from "../components/hero/subtitle"
import { linkStyles } from "../utils/styles"

const NotFoundPage = () => (
  <ThemeProvider>
    <div css={theme => ({ margin: `10vw`, "& a": linkStyles(theme) })}>
      <Container>
        <Title>Page not found</Title>
        <Subtitle
          css={theme => ({
            marginBottom: theme.space[10],
            maxWidth: 400,
          })}
        >
          <em>Sorry</em> 😔—we couldn’t find what you were looking for.
        </Subtitle>
        <p>
          <strong>Need help finding something?</strong>
          <br />
          Email support@gatsbyjs.com and we’ll help you.
        </p>
        <Link to="/">gatsbyjs.com Homepage</Link>
      </Container>
    </div>
  </ThemeProvider>
)

export default NotFoundPage
